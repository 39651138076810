html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.appContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.navbarContainer{
  flex-shrink: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s;
}
.navbar-white {
  background-color: rgba(234, 236, 239, 0.81);
  transition: background-color 0.2s;
}
.brand {
  color: #363636 !important;
}
.nav-item {
  transition: color, 0.2s;
}
.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.socialicons {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
  &:hover {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}

.title {
  height: 100%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;
  @media (min-width: 360px) {
    min-height: 4rem;
  }
  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    box-shadow: 0 2rem 3rem rgba($black, 0.175) !important;
    transform: translateY(-2px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}
.skills-tabs {
  flex-grow: 1;
}
.skills-tab {
  flex-grow: 1;
  text-align: center;
}

.progress {
  transform-origin: left;
  & > * {
    transform: scaleX(0);
  }
}
.progress-bar-animation {
  @extend .progress;
  & > * {
    animation: progress 1.5s ease-in-out forwards;
  }
}
.fontFamilyFantasy{
  font-family: fantasy;
}
.fontFamilyMonospace{
 font-family: monospace;
}
.fontFamilyHelvetica{
 }

.productCardImg { 
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner {
  background-image: url(https://res.cloudinary.com/dt3kx30lt/image/upload/v1686209698/CarbonComposites/Banner/wallhaven-0pe8wj_yvmm83.jpg);
  background-size: cover;
}

.projectsBackground {
  background-image: url(https://res.cloudinary.com/dt3kx30lt/image/upload/v1686209698/CarbonComposites/Banner/wallhaven-0pe8wj_yvmm83.jpg);
  background-size: cover;
}

.stickyFooter {
  flex-shrink: 0;
  // z-index: 999;
  // position: fixed;
  //   bottom: 0;
  //   width: 100%;
}

.hashLink {
  text-decoration: none;
  padding-left: 30px;
  // padding-top: 20px;
  font-size: 20px;
}
.hashLink:visited {
  color: black;
}

.projectDetailsContainer{
  flex-grow: 1;
  overflow: auto;
  min-height: calc(100vh - 232px);
  background-color: #e9ecef;
}

// .columnContainer{
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
// }

.imagesContainer {
  display: flex;
  justify-content: center;
  min-height: 70%;
  position: relative;
}

.imagesGallery {
  width: inherit;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 0;
}

.image-gallery > li {
  /* height: 300px; */
  cursor: pointer;
  position: relative;
  list-style-type: none;
  height: 30vh;
  flex-grow: 1;
}

.image-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}

.overflowWrap{
  overflow-wrap: break-word;
}

.jumboCont{
  max-width: 80%  ;
}

.productCardContainer{
  cursor: pointer;
}

.titleContainer{
  font-size: 90px;
}

@media (max-aspect-ratio: 1/1) {
  li {
    height: 30vh;
  }
}

@media (max-height: 480px) {
  li {
    height: 80vh;
  } 
}
@media (max-width: 991px){
  .titleContainer{
    padding: 0;
    font-size: 60px;
  }  
}
@media (max-width: 480px){
  .titleContainer{
    padding: 0;
    font-size: 40px;
  }  
}

